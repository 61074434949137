<template>
  <v-container fluid grid-list class="container-statistics ma-0 pa-0 ml-8">
    <v-card elevation="0" color="#F5F5F5" width="94vw">
      <v-container fluid grid-list>
        <v-layout wrap d-flex justify-center>
          <v-flex xs12 sm12 md12 lg12>

            <v-container fluid grid-list class="pb-0" >
              <v-layout wrap column>
                <v-card elevation="0" class="pt-4 d-flex flex-column">
                  <v-layout wrap row>
                    <v-flex xs12 sm12 md3 lg2 class="d-flex align-center justify-center">
                      <v-card-title class="font-weight-bold pa-3 ma-0 mr-6">Estadísticas</v-card-title>
                    </v-flex>
                    <v-flex xs12 sm12 md6 lg5 class="d-flex align-center justify-center pa-3">
                      <v-card-subtitle class="pa-0 ma-0">
                        <v-icon small color="#6F50FF" class="mr-2">
                          mdi-help-circle-outline
                        </v-icon>
                        Visualiza tu métricas en la plataforma y mejora tu
                        desempeño
                      </v-card-subtitle>

                    </v-flex>

                  </v-layout>
                  <!-- <v-layout warp row class="marginLeft60">
                        <v-btn class="bg-white">
                          Datos de mi empresa
                        </v-btn>
                        <v-btn class="bg-white">
                          Datos de los Candidatos
                        </v-btn>
                        <v-btn class="bg-white">
                          Respuestas al chat
                        </v-btn>
                      </v-layout> -->

                </v-card>
              </v-layout>
            </v-container>


            <!-- <v-container fluid grid-list class="pl-0 pr-0 pt-0">
              <v-layout wrap column>
                <v-flex>
                  <TopCards :topCardsStats="this.getAllStats" />
                </v-flex>
              </v-layout>
            </v-container> -->

          </v-flex>
        </v-layout>
        
      
        <v-layout row class="pr-3 pl-3">
          <v-flex xs12 sm12 md4 lg4>
            <v-btn color="white" class="d-flex justify-center ma-0" v-for="link in navLinks" :key="link.title"
              :to="link.path" text>
              <v-icon class="mr-4" small color="#6F50FF">{{
                link.icon
              }}</v-icon>
              <p class="text-caption pa-0 ma-0 text-capitalize font-weight-light" style="color: #000">
                sadasdadas
              </p>
            </v-btn>
          </v-flex>

        </v-layout>
        <v-layout v-if="statisticsStateLoaded === true" row class="pr-3 pl-3">
          <v-flex xs12 sm12 md4 lg4>
            <CandidatesCreated :candidatesByBotStats="this.getAllStats.candidates_by_bot"
              :candidatesByAppStats="this.getAllStats.candidates_by_app" />
            <ChatsStarted :chatsStartedStats="this.getAllStats.chats_started" />
          </v-flex>
          <v-flex xs12 sm12 md4 lg4>
            <VacanciesCreated :vacanciesCreatedStats="this.getAllStats.vacancies_created" />
            <ChatsCompleted :chatsCompletedStats="this.getAllStats.chats_completed" />

          </v-flex>
          <v-flex xs12 sm12 md4 lg4>
            <UsersCreated :userCreatedStats="this.getAllStats.users_created" />
            <ChatsIncomplete :chatsIncompleteStats="this.getAllStats.chats_incomplete" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <div v-if="statisticsStateLoaded === false">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-container>
</template>

<script>
import TopCards from "../../components/Statistics/top/TopCards.vue";
import CandidatesCreated from "../../components/Statistics/left/CandidatesCreated.vue";
import ChatsCompleted from "../../components/Statistics/right/ChatsCompleted.vue";
import ChatsIncomplete from "../../components/Statistics/right/ChatsIncomplete.vue";
import ChatsStarted from "../../components/Statistics/right/ChatsStarted.vue";
import CandidatesByApp from "../../components/Statistics/left/CandidatesCreated.vue";
import UsersCreated from "../../components/Statistics/left/UsersCreated.vue";
import VacanciesCreated from "../../components/Statistics/left/VacanciesCreated.vue";
import ENUM from "@/store/enums";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    TopCards,
    // CandidatesHistory,
    // VacanciesPercentage,
    // RecruitmentsAverage,
    // VacanciesAverageCards,
    // RecruitmentsPerLocation,
    CandidatesCreated,
    ChatsCompleted,
    ChatsIncomplete,
    ChatsStarted,
    CandidatesByApp,
    UsersCreated,
    VacanciesCreated
  },
  data: () => ({
    saludito: "Hola",
    testObject: {
      saludo: "hola",
      mensaje: "Mensaje del objeto"
    }
  }),
  computed: {
    ...mapGetters(["getAllStats"]),
    ...mapState({
      statisticsState: (state) => state.statistics.status
    }),
    statisticsStateLoaded() {
      return this.statisticsState === ENUM.LOADED;
    },
  },
  methods: {
    ...mapActions(["requestsAllStats", "requestTest"])
  },
  created() {
    this.requestsAllStats().then(() => {
      console.warn("requestsAllStats was called and returned", this.getAllStats);
    })
  },
};
</script>

<style>
.marginLeft60 {
  margin-left: 60px;
}

.bg-white {
  background-color: white !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}

.container-statistics {
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  background-color: #f5f5f5;
  overflow: hidden;
}
</style>
