<template>
  <v-container fluid grid-list class="pt-0 pb-1">
    <v-layout wrap column>
      <v-card elevation="0" class="mb-4">
        <v-card-subtitle class="black--text font-weight-bold pa-2 pb-0 ma-2 mr-6">
          Vacantes creadas
        </v-card-subtitle>
        <v-divider></v-divider>
        <div class="d-flex align-center justify-center pa-6">
          <canvas id="vacanciesCreatedChartId"></canvas>
        </div>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "CandidatesHistory",
  data: () => ({
  }),
  props: ["vacanciesCreatedStats"],
  mounted() {
    const ctx =  document.getElementById("vacanciesCreatedChartId");
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Vacantes creadas",
            data: this.vacanciesCreatedStats,
            backgroundColor: "#A4A1FB",
            borderColor: "#A4A1FB",
            borderRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        lineTension: 1,
        barThickness: 7,
        scales: {
          x: {
            display: false,
          },
          y: {
            grace: '5%'
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              padding: 30,
            },
          },
        },
      },
    });
  },
};
</script>

<style></style>
