<template>
  <v-container>
    <v-card elevation="0" class="top-card">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        class="ma-0 pa-0 text-left"
        style="width: 12rem"
        :loading="loading"
      >
        <div class="pt-4 pb-4 ml-5 mr-5 d-flex align-center justify-center">
          <v-icon small color="#6F50FF"> mdi-calendar-check-outline </v-icon>
          <div class="d-flex flex-column ml-3">
            <p class="ma-0 pa-0 body-1 font-weight-bold" style="color: #4ad991">
              {{totalVacancies}}
            </p>
            <p class="ma-0 pa-0 caption">Vacantes</p>
          </div>
        </div>
      </v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<script>
import LoadCard from "../../../../mixins/LoadCard";
export default {
  mixins: [LoadCard],
  props: ["totalVacancies"]
};
</script>

<style></style>
