<template>
  <v-container fluid grid-list>
    <v-layout row>
      <v-flex>
        <TotalCandidates :totalCandidates="totalCandidatesSum" />
      </v-flex>
      <v-flex>
        <TotalVacancies :totalVacancies="totalVacanciesSum" />
      </v-flex>
      <v-flex>
        <TotalUsers :totalUsers="totalUsersSum" />
      </v-flex>
      <v-flex>
        <TotalChats :totalChats="totalChatsSum" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TotalCandidates from "./CardsInTopCards/TotalCandidates.vue";
import TotalVacancies from "./CardsInTopCards/TotalVacancies.vue";
import TotalUsers from "./CardsInTopCards/TotalUsers.vue";
import TotalChats from "./CardsInTopCards/TotalChats.vue";
// import TotalNewAppointmentsToday from "./CardsInTopCards/TotalNewAppointmentsToday.vue"

export default {
  data: () => ({
    currenthMonth: null,
    totalCandidatesSum: null,
    totalVacanciesSum: null,
    totalUsersSum: null,
    totalChatsSum: null,
  }),
  props: ["topCardsStats"],
  components: {
    TotalCandidates,
    TotalVacancies,
    TotalUsers,
    TotalChats,
    // TotalNewAppointmentsToday
  },
  methods: {
    sumCandidates(candidatesByApp, candidatesByBot, limitMonth) {
      let sumByApp = 0;
      for (let i = 0; i <= limitMonth; i++) sumByApp += candidatesByApp[i];

      let sumByBot = 0;
      for (let i = 0; i <= limitMonth; i++) sumByBot += candidatesByBot[i];

      return sumByApp + sumByBot;
    },
    sumVacancies(vacanciesCreated, limitMonth) {
      let sum = 0;
      for (let i = 0; i <= limitMonth; i++) sum += vacanciesCreated[i];
      return sum;
    },
    sumUsers(usersCreated, limitMonth) {
      let sum = 0;
      for (let i = 0; i <= limitMonth; i++) sum += usersCreated[i];
      return sum;
    },
    sumChats(chatsStarted, limitMonth) {
      let sum = 0;
      for (let i = 0; i <= limitMonth; i++) sum += chatsStarted[i];
      return sum;
    },
  },
  created() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth();

    this.totalCandidatesSum = this.sumCandidates(
      this.topCardsStats.candidates_by_app,
      this.topCardsStats.candidates_by_bot,
      this.currentMonth
    );

    this.totalVacanciesSum = this.sumVacancies(
      this.topCardsStats.vacancies_created,
      this.currentMonth
    );

    this.totalUsersSum = this.sumUsers(
      this.topCardsStats.users_created,
      this.currentMonth
    );

    this.totalChatsSum = this.sumChats(
      this.topCardsStats.chats_started,
      this.currentMonth
    );
  },
};
</script>

<style></style>
